import { branchUserRegister } from "./authServices";
import { app, getDBInstance, ObjectId, branchApp } from "./dbServices";
// import { User } from "realm-web";

export const AddRestaurantValues = async ({
  data,
  location,
  address,
  userType,
}: any) => {
  if (!data) {
    return;
  }

  const existingRestaurant = await getDBInstance(userType)
    .collection("branch")
    .findOne({ username: data.username });
  if (existingRestaurant) {
    throw new Error("A restaurant with this email already exists.");
  }
  const selectedApp = userType === "branch" ? branchApp : app;
  const categoryObjectIds = data.category.map((id: string) => new ObjectId(id));
  const cuisineObjectIds = data.cuisine.map((id: string) => new ObjectId(id));

  const result = await getDBInstance(userType)
    .collection("restaurant")
    .insertOne({
      name: { en: data.name },
      description: data.description,
      metaData: {
        createdAt: new Date(),
        createdBy: data.createdBy || selectedApp.currentUser.id,
        modifiedAt: null,
        modifiedBy: null,
        isActive: data.isActive,
      },
    });

  const branchResult = await getDBInstance(userType)
    .collection("branch")
    .insertOne({
      restaurantId: result.insertedId,
      workingHours: data.workingHours,
      type: data.type,
      category: categoryObjectIds,
      cuisine: cuisineObjectIds,
      orderType: data.orderType,
      contactDetails: data.contactDetails,
      username: data.username,
      password: data.password,
      paymentStatus: data.paymentStatus,
      logo: data.logo,
      banner: data.banner,
      address: {
        location: { type: "Point", coordinates: [location.lng, location.lat] },
        address: address,
      },
      metaData: {
        createdAt: new Date(),
        createdBy: data.createdBy || selectedApp.currentUser.id,
        modifiedAt: null,
        modifiedBy: null,
        isActive: data.isActive,
      },
    });

  try {
    await branchApp.emailPasswordAuth.registerUser({
      email: data.username,
      password: data.password,
    });
    console.log("Realm user created successfully for branch.");

    const registerResult = await branchUserRegister({
      data: data,
      branchId: branchResult.insertedId,
      currentApp: selectedApp,
    });
    console.log("Additional user registration success:", registerResult);
  } catch (error: any) {
    console.error("Failed to create Realm user:", error.message);
    if (error.message.includes("name already in use")) {
      throw new Error(
        "A user with this email already exists in the branch app."
      );
    } else {
      throw new Error(
        `Failed to create Realm user for branch: ${error.message}`
      );
    }
  }

  return branchResult;
};

// export const getRestaurantValues = async ({
//   page = 0,
//   rowsPerPage = 10,
//   userType,
// }: any) => {
//   const skip = page * rowsPerPage;
//   const limit = rowsPerPage;

//   const totalDocumentCount = await getDBInstance(userType)
//     .collection("restaurant")
//     .count({});

//   const result = await getDBInstance(userType)
//     .collection("restaurant")
//     .aggregate([
//       {
//         $match: {},
//       },
//       {
//         $lookup: {
//           from: "branch",
//           localField: "_id",
//           foreignField: "restaurantId",
//           as: "branchDetails",
//         },
//       },
//       {
//         $sort: {
//           _id: -1,
//         },
//       },
//       { $skip: skip },
//       { $limit: limit },
//     ]);

//   return { data: result, totalDocumentCount };
// };

export const getRestaurantValues = async ({
  page = 0,
  rowsPerPage = 10,
  userType,
}: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const db = getDBInstance(userType);
  let matchCondition = {};

  if (userType === "branch" && branchApp.currentUser) {
    const branchEmail = branchApp.currentUser.profile.email;

    if (branchEmail) {
      matchCondition = { "branchDetails.username": branchEmail };
    }
  }

  const totalDocumentCountResult = await db.collection("restaurant").aggregate([
    {
      $lookup: {
        from: "branch",
        localField: "_id",
        foreignField: "restaurantId",
        as: "branchDetails",
      },
    },
    {
      $unwind: "$branchDetails",
    },
    {
      $match: matchCondition,
    },
    {
      $count: "count",
    },
  ]);

  const totalDocumentCount = totalDocumentCountResult[0]?.count || 0;

  const result = await db.collection("restaurant").aggregate([
    {
      $lookup: {
        from: "branch",
        localField: "_id",
        foreignField: "restaurantId",
        as: "branchDetails",
      },
    },
    {
      $unwind: "$branchDetails",
    },
    {
      $match: matchCondition,
    },
    {
      $sort: { _id: -1 },
    },
    {
      $skip: skip,
    },
    {
      $limit: limit,
    },
  ]);

  return {
    data: result,
    totalDocumentCount,
  };
};

export const getOneRestaurant = async ({ id, userType }: any) => {
  try {
    const restaurantObjectId = new ObjectId(id);

    const result = await getDBInstance(userType)
      .collection("restaurant")
      .aggregate([
        {
          $match: {
            _id: restaurantObjectId,
          },
        },
        {
          $lookup: {
            from: "branch",
            localField: "_id",
            foreignField: "restaurantId",
            as: "branchDetails",
          },
        },
        {
          $unwind: {
            path: "$branchDetails",
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $lookup: {
            from: "modifiers",
            localField: "branchDetails._id",
            foreignField: "branchId",
            as: "branchDetails.modifiers",
          },
        },
        {
          $lookup: {
            from: "menu",
            localField: "branchDetails._id",
            foreignField: "branchId",
            as: "branchDetails.menuItems",
          },
        },
        {
          $lookup: {
            from: "categories",
            localField: "branchDetails.category",
            foreignField: "_id",
            as: "branchDetails.categoryDetails",
          },
        },
        {
          $group: {
            _id: "$_id",
            name: { $first: "$name" },
            description: { $first: "$description" },
            metaData: { $first: "$metaData" },
            branchDetails: {
              $push: {
                _id: "$branchDetails._id",
                address: "$branchDetails.address",
                banner: "$branchDetails.banner",
                category: "$branchDetails.category",
                categoryDetails: "$branchDetails.categoryDetails",
                cuisine: "$branchDetails.cuisine",
                contactDetails: "$branchDetails.contactDetails",
                logo: "$branchDetails.logo",
                menuItems: "$branchDetails.menuItems",
                modifiers: "$branchDetails.modifiers",
                orderType: "$branchDetails.orderType",
                paymentStatus: "$branchDetails.paymentStatus",
                type: "$branchDetails.type",
                username: "$branchDetails.username",
                password: "$branchDetails.password",
                workingHours: "$branchDetails.workingHours",
                metaData: "$branchDetails.metaData",
              },
            },
          },
        },
      ]);
    return result.length ? result[0] : null;
  } catch (error) {
    console.error("Error fetching restaurant:", error);
    return null;
  }
};

// Delete restaurant
// export const deleteRestaurant = async (
//   restaurantId: string,
//   userType: string
// ) => {
//   const db = getDBInstance(userType);
//   const restaurantObjectId = new ObjectId(restaurantId);

//   const restaurantDeleteResult = await db.collection("restaurant").deleteOne({
//     _id: restaurantObjectId,
//   });

//   const branches = await db.collection("branch").find({
//     restaurantId: restaurantObjectId,
//   });

//   const branchIds = branches.map((branch) => branch._id);

//   const branchDeleteResult = await db.collection("branch").deleteMany({
//     restaurantId: restaurantObjectId,
//   });
//   const modifierDeleteResult = await db.collection("modifiers").deleteMany({
//     branchId: { $in: branchIds },
//   });
//   const menuDeleteResult = await db.collection("menu").deleteMany({
//     branchId: { $in: branchIds },
//   });

//   return {
//     restaurantDeleteResult,
//     branchDeleteResult,
//     modifierDeleteResult,
//     menuDeleteResult,
//   };
// };

export const deleteRestaurant = async (
  restaurantId: string,
  userType: string
) => {
  const db = getDBInstance(userType);
  const restaurantObjectId = new ObjectId(restaurantId);

  // Delete restaurant document
  const restaurantDeleteResult = await db.collection("restaurant").deleteOne({
    _id: restaurantObjectId,
  });

  // Find branch documents
  const branches = await db.collection("branch").find({
    restaurantId: restaurantObjectId,
  });

  const branchIds = branches.map((branch) => branch._id);

  // Delete branch, modifiers, menu, and businessUsers associated with branchIds
  const branchDeleteResult = await db.collection("branch").deleteMany({
    restaurantId: restaurantObjectId,
  });
  const modifierDeleteResult = await db.collection("modifiers").deleteMany({
    branchId: { $in: branchIds },
  });
  const menuDeleteResult = await db.collection("menu").deleteMany({
    branchId: { $in: branchIds },
  });

  const orderDeleteResult = await db.collection("orders").deleteMany({
    branchId: { $in: branchIds },
  });
  const businessUserDeleteResult = await db
    .collection("businessUsers")
    .deleteMany({
      branchId: { $in: branchIds },
    });

  // Use allUsers to delete Realm users associated with branches
  // for (const branch of branches) {
  //   try {
  //     const email = branch.username;

  //     const userToDelete = Object.values(branchApp.allUsers).find(
  //       (user) => user.profile.email === email
  //     );

  //     if (userToDelete) {
  //       await branchApp.removeUser(userToDelete); // Removes the user from Realm
  //       console.log(`Deleted Realm user for branch with email: ${email}`);
  //     }
  //   } catch (error) {
  //     console.error(`Failed to delete Realm user for ${branch.username}:`, error);
  //   }
  // }

  return {
    restaurantDeleteResult,
    branchDeleteResult,
    modifierDeleteResult,
    menuDeleteResult,
    businessUserDeleteResult,
    orderDeleteResult,
  };
};

export const updateRestaurant = async ({
  restaurantId,
  data,
  location,
  address,
  userType,
}: any) => {
  const selectedApp = userType === "branch" ? branchApp : app;
  const categoryObjectIds = data.category.map((id: string) => new ObjectId(id));
  const cuisineObjectIds = data.cuisine.map((id: string) => new ObjectId(id));

  try {
    const db = getDBInstance(userType);
    const restaurantObjectId = new ObjectId(restaurantId);

    // Update restaurant information
    const restaurantUpdateResult = await db.collection("restaurant").updateOne(
      { _id: restaurantObjectId },
      {
        $set: {
          name: { en: data.name },
          description: data.description,
          metaData: {
            createdAt: new Date(),
            createdBy: data.createdBy || selectedApp.currentUser.id,
            modifiedAt: new Date(),
            modifiedBy: data.modifiedBy || selectedApp.currentUser.id,
            isActive: data.isActive,
          },
        },
      }
    );

    // Prepare the query for branch update
    const query: any = {
      workingHours: data.workingHours,
      type: data.type,
      category: categoryObjectIds,
      cuisine: cuisineObjectIds,
      orderType: data.orderType,
      contactDetails: data.contactDetails,
      username: data.username,
      password: data.password,
      paymentStatus: data.paymentStatus,
      logo: data.logo,
      banner: data.banner,
      address: {
        address: address,
      },
      metaData: {
        createdAt: new Date(),
        createdBy: data.createdBy || selectedApp.currentUser.id,
        modifiedAt: new Date(),
        modifiedBy: data.modifiedBy || selectedApp.currentUser.id,
        isActive: data.isActive,
      },
    };

    // If location data is available, add it to the query
    if (location && location?.lng && location?.lat) {
      query.address.location = {
        type: "Point",
        coordinates: [location.lng, location.lat],
      };
    }

    // Update the branch information
    const branchUpdateResult = await db.collection("branch").updateOne(
      { restaurantId: restaurantObjectId },
      {
        $set: query,
      }
    );

    // Retrieve the updated branch information
    const updatedBranch = await db
      .collection("branch")
      .findOne(
        { restaurantId: restaurantObjectId },
        { projection: { _id: 1 } }
      );

    return {
      restaurantUpdateResult,
      branchUpdateResult,
      branchId: updatedBranch?._id,
    };
  } catch (error) {
    console.error("Error updating restaurant:", error);
    return null;
  }
};

export const UpdateOneRestaurantStatus = async ({
  id,
  isActive,
  userType,
}: any) => {
  if (!id) {
    return;
  }

  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const db = getDBInstance(userType);

  try {
    // Update `isActive` status in the `restaurant` collection
    const restaurantUpdateResult = await db.collection("restaurant").updateOne(
      {
        _id: id,
      },
      {
        $set: {
          "metaData.isActive": isActive,
        },
      }
    );

    // Update `isActive` status in the `branch` collection
    const branchUpdateResult = await db.collection("branch").updateMany(
      {
        restaurantId: id,
      },
      {
        $set: {
          "metaData.isActive": isActive,
        },
      }
    );

    return {
      restaurantUpdateResult,
      branchUpdateResult,
    };
  } catch (error) {
    console.error("Error updating restaurant and branch status:", error);
    return null;
  }
};
